<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs3 text-left>
            <span class="mainHeader"> BILLING</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-6 text-left>
        <v-layout wrap py-2>
          <v-flex xs12 pt-2>
            <v-card
              tile
              elevation="0"
              class="rounded-lg pa-2"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap px-4 justify-center>
                <v-flex xs12 sm5 py-1 text-left>
                  <v-layout wrap pb-1 justify-center>
                    <v-flex xs6 v-if="customerName" class="subheeed3 text-left"
                      >Customer Name:</v-flex
                    >
                    <v-flex
                      xs6
                      pl-2
                      v-if="customerName"
                      class="subheeed33 text-left"
                      >{{ customerName }}</v-flex
                    >
                  </v-layout>
                  <v-layout wrap pb-1 justify-center>
                    <v-flex xs6 v-if="contact" class="subheeed3 text-left"
                      >Contact:</v-flex
                    >
                    <v-flex
                      xs6
                      pl-2
                      v-if="contact"
                      class="subheeed33 text-left"
                      >{{ contact }}</v-flex
                    >
                  </v-layout>
                  <v-layout wrap pb-1 justify-center>
                    <v-flex xs6 v-if="address" class="subheeed3 text-left"
                      >Address:</v-flex
                    >
                    <v-flex
                      xs6
                      pl-2
                      v-if="address"
                      class="subheeed33 text-left"
                      >{{ address }}</v-flex
                    >
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 sm3 py-1>
                  <v-layout wrap pb-1 justify-center>
                    <v-flex xs6 v-if="estno" class="subheeed3 text-left"
                      >Estimate No:</v-flex
                    >
                    <v-flex
                      xs6
                      pl-2
                      v-if="estno"
                      class="subheeed33 text-left"
                      >{{ estno }}</v-flex
                    >
                  </v-layout>
                  <v-layout wrap pb-1 justify-center>
                    <v-flex xs6 v-if="salesmenCode" class="subheeed3 text-left"
                      >Staff Code:</v-flex
                    >
                    <v-flex
                      xs6
                      pl-2
                      v-if="salesmenCode"
                      class="subheeed33 text-left"
                      >{{ salesmenCode }}</v-flex
                    >
                  </v-layout>
                  <v-layout wrap pb-1 justify-center>
                    <v-flex xs6 v-if="date" class="subheeed3 text-left"
                      >Date:</v-flex
                    >
                    <v-flex xs6 pl-2 v-if="date" class="subheeed33 text-left">{{
                      formatDate(date)
                    }}</v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <!---------------------ESTIMATE DETAILS------------------------->

        <!-- <v-layout wrap>
          <v-flex xs12 pt-6>
            <v-card
              tile
              elevation="0"
              class="rounded-lg"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap pa-4 class="subhed3">
                <v-flex xs12 sm2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>Estimate No</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="estno"
                        outlined
                        color="#b234a9"
                        disabled
                        placeholder="Estimate"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>Date</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="date"
                        outlined
                        color="#b234a9"
                        disabled
                        placeholder="Date"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>Salesman code</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="salesmenCode"
                        outlined
                        color="#b234a9"
                        disabled
                        placeholder="Salesmen"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout> -->
        <!---------------------CUSTOMER DETAILS------------------------->

        <!-- <v-layout wrap>
          <v-flex xs12 py-6>
            <v-card
              tile
              elevation="0"
              class="rounded-lg"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap>
                <v-flex xs12 pt-4 pl-4 class="mainHeader2">
                  Customer Details
                </v-flex>
              </v-layout>
              <v-layout wrap pa-4 class="subhed3">
                <v-flex xs12 sm3 lg2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>Customer Name</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="customerName"
                        outlined
                        color="#b234a9"
                        placeholder="Customer"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm3 lg2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>Address</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="address"
                        outlined
                        color="#b234a9"
                        placeholder="Address"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm3 lg2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>City</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="city"
                        outlined
                        color="#b234a9"
                        placeholder="City"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm3 lg2 pr-2>
                  <v-layout wrap>
                    <v-flex xs12> <span>Mobile Number</span></v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="contact"
                        outlined
                        color="#b234a9"
                        placeholder="Contact"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout> -->

        <!---------------------ITEM DETAILS------------------------->

        <v-layout wrap>
          <v-flex xs12 py-2>
            <v-card
              tile
              elevation="0"
              class="rounded-lg"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap px-4 pt-4>
                <v-flex xs12 class="mainHeader2">Sales</v-flex>
              </v-layout>

              <!---------------------Table-------------------------->
              <v-layout px-4 py-2 wrap>
                <!-- <v-flex xs12 class="subhed2">Quotation {{ quotation }}</v-flex> -->
                <v-flex xs12 v-if="List">
                  <v-card elevation="0" v-if="List.length > 0">
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left tablefont"><b>S.No.</b></th>
                                <th class="text-left tablefont">
                                  <b>Item</b>
                                </th>
                                <th class="text-left tablefont"><b>HSN</b></th>
                                <th class="text-left tablefont">
                                  <b>Qty</b>
                                </th>
                                <!-- <th class="text-left tablefont">
                                  <b>Gr.wt(gm)</b>
                                </th> -->
                                <th class="text-left tablefont">
                                  <b>St.wt(gm)</b>
                                </th>
                                <th class="text-left tablefont">
                                  <b>Net.wt(gm)</b>
                                </th>
                                <th class="text-left tablefont">
                                  <b>St.pr(₹)</b>
                                </th>
                                <th class="text-left tablefont">
                                  <b>Mk.Chg(₹)</b>
                                </th>
                                <th class="text-left tablefont"><b>VA(%)</b></th>
                                <th class="text-left tablefont">
                                  <b>Discount(₹)</b>
                                </th>
                                <!-- <th class="text-left tablefont">
                                  <b>Ad.MK.Chg(₹)</b>
                                </th>
                                <th class="text-left tablefont">
                                  <b>Ad.VA(%)</b>
                                </th> -->
                                <th class="text-left tablefont">
                                  <b>Total(₹)</b>
                                </th>
                                <th
                                  class="text-left tablefont"
                                  v-if="taxType == 'true'"
                                >
                                  <b>GST Total(₹)</b>
                                </th>

                                <!-- <th class="text-left tablefont"><b>Action</b></th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value, i) in List"
                                :key="i"
                                class="table"
                              >
                                <!-- <td>{{ i + 1 }}</td> -->
                                <td>
                                  <span>
                                    {{ i + 1 }}
                                  </span>
                                </td>
                                <td>
                                  <span v-if="value.designId"
                                    >{{ value.designId.name }}-{{
                                      value.itemId.name
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.designId.HSN">
                                    {{ value.designId.HSN.hsnCode }}</span
                                  >
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.quantity">{{
                                    value.quantity
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <!-- <td>
                                  <span v-if="value.grossWeight">{{
                                    value.grossWeight
                                  }}</span>
                                  <span v-else>0</span>
                                </td> -->
                                <td>
                                  <span v-if="value.stonWeight">{{
                                    value.stonWeight
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value.netWeight">{{
                                    value.netWeight
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value.stonWeight">{{
                                    value.stonWeight
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value.adjustedMakingCharge">{{
                                    value.adjustedMakingCharge.toFixed(2)
                                  }}</span>
                                  <span v-else>{{
                                    value.makingCharge.toFixed(2)
                                  }}</span>
                                </td>
                                <td>
                                  <span v-if="value.adjustedVA">{{
                                    value.adjustedVA.toFixed(2)
                                  }}</span>
                                  <span v-else>{{
                                    value.valueAddition.toFixed(2)
                                  }}</span>
                                </td>
                                <!-- <td>
                                  <span v-if="value.makingCharge">{{
                                    (value.makingCharge).toFixed(2)
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value.valueAddition">{{
                                    (value.valueAddition).toFixed(2)
                                  }}</span>
                                  <span v-else>0</span>
                                </td> -->
                                <td>
                                  <span v-if="value.discountAmount">{{
                                    value.discountAmount.toFixed(2)
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <!-- <td>
                                  <span v-if="value.adjustedMakingCharge">{{
                                    (value.adjustedMakingCharge).toFixed(2)
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value.adjustedVA">{{
                                    (value.adjustedVA).toFixed(2)
                                  }}</span>
                                  <span v-else>0</span>
                                </td> -->
                                <td>
                                  <span v-if="value.amountWithoutGst">{{
                                    value.amountWithoutGst.toFixed(2)
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td v-if="taxType == 'true'">
                                  <span v-if="value.amountWithGst">{{
                                    value.amountWithGst.toFixed(2)
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <!-- <td> -->
                                  <!-- <v-icon
                                size="18"
                                class="pr-2"
                                color="#3F053C"
                                style="cursor: pointer"
                                @click="
                                  (curItem = value),
                                    (tstcat = value.designId),
                                    (editdialogue = true),
                                    getCategory()
                                "
                                >mdi-pencil</v-icon
                              > -->
                                  <!-- <v-icon
                                    size="18"
                                    style="cursor: pointer"
                                    @click="
                                      (dialoge = true), (curId = value._id)
                                    "
                                    >mdi-delete-outline</v-icon
                                  >
                                </td> -->
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <!----------------------old gold--------------------------->
        <v-layout wrap v-if="List2">
          <v-flex xs12 py-2 v-if="List2.length > 0">
            <v-card
              tile
              elevation="0"
              class="rounded-lg"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap px-4 py-2>
                <v-flex xs6 class="mainHeader2">Old Gold</v-flex>
                <v-spacer></v-spacer>

                <v-btn icon @click="show1 = !show1">
                  <v-icon>{{
                    show1 ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-layout>
              <v-expand-transition>
                <div v-show="show1">
                  <v-layout px-4 wrap>
                    <v-flex xs12 v-if="List2">
                      <v-card elevation="0" v-if="List2.length > 0">
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left tablefont">
                                      <b>S.No.</b>
                                    </th>
                                    <th class="text-left tablefont">
                                      <b>Item</b>
                                    </th>
                                    <th class="text-left tablefont">
                                      <b>Rate(₹)</b>
                                    </th>
                                    <th class="text-left tablefont">
                                      <b>Qty</b>
                                    </th>
                                    <th class="text-left tablefont">
                                      <b>Gr.wt(gm)</b>
                                    </th>
                                    <th class="text-left tablefont">
                                      <b>St.wt(gm)</b>
                                    </th>
                                    <th class="text-left tablefont">
                                      <b>Net.wt(gm)</b>
                                    </th>
                                    <th class="text-left tablefont">
                                      <b>Melt(%)</b>
                                    </th>
                                    <th class="text-left tablefont">
                                      <b>Total(₹)</b>
                                    </th>
                                    <!-- <th class="text-left tablefont">
                                      <b>Action</b>
                                    </th> -->
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(value2, i) in List2"
                                    :key="i"
                                    class="table"
                                  >
                                    <td>
                                      <span>
                                        {{ i + 1 }}
                                      </span>
                                    </td>
                                    <td>
                                      <span v-if="value2.itemName">{{
                                        value2.itemName
                                      }}</span>
                                      <span v-else>-</span>
                                    </td>
                                    <td>
                                      <span v-if="value2.goldRate">
                                        {{ value2.goldRate }}</span
                                      >
                                      <span v-else>-</span>
                                    </td>
                                    <td>
                                      <span v-if="value2.quantity">{{
                                        value2.quantity
                                      }}</span>
                                      <span v-else>-</span>
                                    </td>
                                    <td>
                                      <span v-if="value2.grossWeight">{{
                                        value2.grossWeight
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <td>
                                      <span v-if="value2.stoneWeight">{{
                                        value2.stoneWeight
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <td>
                                      <span v-if="value2.netWeight">{{
                                        value2.netWeight
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <td>
                                      <span v-if="value2.melt">{{
                                        value2.melt
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <td>
                                      <span v-if="value2.itemAmount">{{
                                        value2.itemAmount.toFixed(2)
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <!-- <td> -->
                                      <!--   <v-icon
                                size="18"
                                class="pr-2"
                                color="#3F053C"
                                style="cursor: pointer"
                                @click="
                                  (curitem = value),
                                    (tstcat = value.designId),
                                    (editdialogue = true),
                                    getItem()
                                "
                                >mdi-pencil</v-icon
                              >-->
                                      <!-- <v-icon
                                        size="18"
                                        style="cursor: pointer"
                                        @click="
                                          (dialoge2 = true),
                                            (curId2 = value2._id)
                                        "
                                        >mdi-delete-outline</v-icon
                                      >
                                    </td> -->
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </div>
              </v-expand-transition>
            </v-card>
          </v-flex>
        </v-layout>
        <!----------------------return gold--------------------------->
        <v-layout wrap v-if="List3">
          <v-flex xs12 py-2 v-if="List3.length > 0">
            <v-card
              tile
              elevation="0"
              class="rounded-lg"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap px-4 py-2>
                <v-flex xs6 class="mainHeader2">Return Gold</v-flex>
                <v-spacer></v-spacer>

                <v-btn icon @click="show = !show">
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-layout>
              <v-expand-transition>
                <div v-show="show">
                  <v-layout px-4 wrap>
                    <v-flex xs12 v-if="List3">
                      <v-card v-if="List3.length > 0" elevation="0">
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left tablefont">
                                      S.No.
                                    </th>
                                    <th class="text-left tablefont" style="width:35%!important">
                                      Item
                                    </th>
                                    <th class="text-left tablefont">HSN</th>
                                    <th class="text-left tablefont">
                                      Qty
                                    </th>
                                    <th class="text-left tablefont">
                                      Gr.wt(gm)
                                    </th>
                                    <th class="text-left tablefont">
                                      St.wt(gm)
                                    </th>
                                    <th class="text-left tablefont">
                                      Net.wt(gm)
                                    </th>
                                    <th class="text-left tablefont">
                                      Total(₹)
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(value3, i) in List3"
                                    :key="i"
                                    class="table"
                                  >
                                  <td>
                                      <span>
                                        {{ i + 1 }}
                                      </span>
                                    </td>
                                    <td>
                                      <span v-if="value3.designId"
                                        >{{ value3.designId.name }}-{{
                                          value3.itemId.name
                                        }}</span
                                      >
                                      <span v-else>-</span>
                                    </td>
                                    <td>
                                      <span v-if="value3.designId">
                                        {{ value3.designId.HSN.hsnCode }}</span
                                      >
                                      <span v-else>-</span>
                                    </td>
                                    <td>
                                      <span v-if="value3.quantity">{{
                                        value3.quantity
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <td>
                                      <span v-if="value3.grossWeight">{{
                                        value3.grossWeight
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <td>
                                      <span v-if="value3.stonWeight">{{
                                        value3.stonWeight
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <td>
                                      <span v-if="value3.netWeight">{{
                                        value3.netWeight
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <!-- <td>
                              <span v-if="value3.stonePrice">{{
                                value3.stonePrice
                              }}</span>
                              <span v-else>0</span>
                            </td> -->
                                    <td>
                                      <span v-if="value3.itemTotalAmount">{{
                                        value3.itemTotalAmount.toFixed(2)
                                      }}</span>
                                      <span v-else>0</span>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </div>
              </v-expand-transition>
            </v-card>
          </v-flex>
        </v-layout>
        <!--------------------- Payment Method-------------------------->

        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card
              tile
              elevation="0"
              class="rounded-lg"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#ffffff'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-layout wrap pa-4>
                <v-flex xs6>
                  <v-layout wrap>
                    <v-flex xs12 py-2 class="mainHeader2">
                      Payment Summary
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pb-1 justify-start v-if="SchemeMaturedTotalAmount">
                    <v-flex xs4 v-if="SchemeMaturedTotalAmount" class="subheeed3 text-left"
                      >Customer Balance :</v-flex
                    >
                    <v-flex xs3 v-if="SchemeMaturedTotalAmount" class="subheeed3 text-left"
                      >{{ SchemeMaturedTotalAmount }}(₹)</v-flex
                    >
                    </v-layout>
                  <v-layout wrap pb-1 justify-start>
                    <v-flex xs3 v-if="ItemTotal" class="subheeed3 text-left"
                      >Sales :</v-flex
                    >
                    <v-flex xs3 v-if="ItemTotal" class="subheeed3 text-left"
                      >{{ ItemTotal }}(₹)</v-flex
                    >
                    <v-flex
                      xs3
                      pl-2
                      v-if="saledata.totalNetWeight"
                      class="subheeed33 text-left"
                    >
                      {{ saledata.totalNetWeight.toFixed(2) }} (gm)</v-flex
                    >
                  </v-layout>
                  <v-layout wrap pb-1 justify-start>
                    <v-flex
                      xs3
                      v-if="OldGoldTotal"
                      class="subheeed3 text-left"
                      >OldGold :</v-flex
                    >
                    <v-flex
                      xs3
                      v-if="OldGoldTotal"
                      class="subheeed3 text-left"
                      >{{ OldGoldTotal }}(₹)</v-flex
                    >
                    <v-flex
                      xs3
                      pl-2
                      v-if="oldGoldData.totalNetWeight"
                      class="subheeed33 text-left"
                    >
                      {{ oldGoldData.totalNetWeight.toFixed(2) }} (gm)</v-flex
                    >
                  </v-layout>
                  <v-layout wrap pb-1 justify-start>
                    <v-flex
                      xs3
                      v-if="returnItemTotal"
                      class="subheeed3 text-left"
                      >Returns :</v-flex
                    >
                    <v-flex
                      xs3
                      v-if="returnItemTotal"
                      class="subheeed3 text-left"
                      >{{ returnItemTotal }}(₹)</v-flex
                    >
                    <v-flex
                      xs3
                      pl-2
                      v-if="returnItemNetWt"
                      class="subheeed33 text-left"
                    >
                      {{ returnItemNetWt }} (gm)</v-flex
                    >
                  </v-layout>
                  <v-layout wrap pb-1 justify-start v-if="paymentStatus=='PartiallyPaid'">
                    <v-flex xs3 v-if="actualBillTotal" class="subheeed3 text-left"
                      >Bill Total:</v-flex
                    >
                    <v-flex xs3 v-if="actualBillTotal" class="subheeed3 text-left"
                      >{{ (actualBillTotal).toFixed(2) }}(₹)</v-flex
                    >
                  </v-layout>
                  <v-layout wrap pb-1 justify-start>
                    <v-flex xs3 v-if="billPaidAmount1" class="subheeed3 text-left"
                      >Paid Amount:</v-flex
                    >
                    <v-flex xs3 v-if="billPaidAmount1" class="subheeed3 text-left"
                      >{{ (billPaidAmount1).toFixed(2) }}(₹)</v-flex
                    >
                  </v-layout>
                  <v-layout wrap pb-1 justify-start v-if="payamount && !billPaidAmount1">
                    <v-flex xs12 v-if="payamount>0">
                      <v-layout wrap py-2>
                        <v-flex xs12 sm3 class="text-left subheeed3" pr-4 align-self-center>
                          Discount:
                          </v-flex>
                        <v-flex xs12 sm4>
                          <v-text-field
                            outlined
                          placeholder="Enter discount amt"
                            v-model="discount"
                            color="#b234a9"
                            dense
                            hide-details
                          ></v-text-field
                        ></v-flex>
                        <v-flex xs12 sm4 align-self-center pl-2>
                          <v-btn v-if="discountPresent==true" dark color="#3F053C" @click="removediscount()" >Remove</v-btn>
                          <v-btn v-else dark color="#3F053C" @click="caldiscount()" >Apply</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap py-1>
                    <v-flex xs12 sm9>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="payamount"
                    ><v-flex xs12 text-left class="mainhead2"
                      >Payable Amount(₹) : {{ payamount }}</v-flex
                    ></v-layout
                  >
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs5>
                  <!--------------- gold scheme --------->
                  <!-- <v-layout wrap v-if="goldSchemeData">
                    <v-flex xs12 sm6 class="mainHeader2">
                      <v-select
                    color="#b234a9"
                    :items="goldSchemeData"
                    v-model="goldSchemeId"
                    placeholder="Select Scheme"
                    item-text="goldSchemeName"
                    item-value="_id"
                    @input="selectThis(goldSchemeId)"
                    outlined
                    hide-details
                    dense
                  ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 class="mainHeader2" v-if="schemeAmtAvailable">
                      <v-text-field
                        v-model="schemeAmtAvailable"
                        dense
                        label="Amount"
                        outlined
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-flex>
                  </v-layout> -->
                   <!------------------------------------->
                  <v-layout wrap v-if="payamount">
                    <v-flex xs12 py-2 class="mainHeader2" v-if="payamount>0">
                      Payment Type:
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="paymentTypeList">
                    <v-flex xs5 pr-2 v-if="paymentTypeList.length>0 && pendingAmount>0">
                      <v-select
                        :items="paymentTypeList"
                        v-model="paytype"
                        label="Select Payment Type"
                        dense
                        hide-details="auto"
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs5 pr-2 v-if="paymentTypeList.length>0 && pendingAmount>0">
                      <v-text-field
                        v-model="purchaseAmount"
                        dense
                        label="Amount"
                        outlined
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2 class="buttons1" align-self-center v-if="paymentTypeList.length>0 && pendingAmount>0">
                      <!-- <v-icon color="#3F053C" @click="addValues()"
                        >mdi-plus</v-icon
                      > -->
                      <v-btn class="buttons1"  dark color="#3F053C" @click="addValues()" >Add</v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 pt-1 v-for="(item, i) in paymentType" :key="i">
                      <v-layout wrap pt-2>
                        <v-flex xs12 lg5 pt-4 pt-lg-0 pr-lg-1>
                          <v-card flat tile elevation="2" class="pa-2">{{ item.paytype }}</v-card>
                          <!-- <v-text-field
                            v-model="item.paytype"
                            dense
                            type="text"
                            :label="paytype"
                            solo
                            hide-details
                          ></v-text-field> -->
                        </v-flex>
                        <v-flex xs12 lg5 pr-lg-1>
                          <v-card flat tile elevation="2" class="pa-2">{{ item.purchaseAmount }}</v-card>
                          <!-- <v-text-field
                            v-model="item.purchaseAmount"
                            solo
                            dense
                            hide-details
                            class="my-0 py-0"
                          ></v-text-field> -->
                        </v-flex>
                        <v-flex xs12 lg1 pt-2 pt-lg-0 align-self-center>
                          <v-icon
                            color="#3F053C"
                            @click="
                              paymentTypeList.push(item.paytype),
                                calcPrice(item.purchaseAmount,i)
                            "
                          >
                            mdi-close
                          </v-icon>
                        </v-flex>
                      </v-layout>
                    
                    </v-flex>
                  </v-layout>
                  <v-layout wrap py-2 v-if="pendingAmount">
                        <v-flex xs12 v-if="pendingAmount>0" class="subheeed3 text-left" pt-2>Pending Amt(₹): {{ pendingAmount }}</v-flex>
                      </v-layout>
                </v-flex>
              </v-layout>

              <!-- <v-layout
                    wrap
                    py-4
                    v-for="(data, i) in textFieldArray"
                    :key="i"
                  >
                    <v-flex xs 5 text-right pr-4 align-self-center>
                      Payment Type :
                    </v-flex>
                    <v-flex xs2 pr-5>
                      <v-form @submit.prevent="validateInput">
                        <v-select
                          :items="payty"
                          dense
                          v-model="pay"
                          outlined
                          style="background-color: white subhed2"
                          color="#b234a9"
                          hide-details
                        ></v-select> </v-form
                    ></v-flex>
                    <v-flex xs1 align-self-center> Amount :</v-flex>
                    <v-flex xs2 pr-3>
                      <v-text-field
                        
                        v-model="finalamount"
                        outlined
                        color="#b234a9"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-flex>
                    <v-flex xs1 align-self-center text-left
                      ><v-icon @click="addMultiple()" color="#3F053C"
                        >mdi-plus-box</v-icon
                      >
                      <v-icon @click="deleteItem(i)" color="#3F053C"
                        >mdi-close-box</v-icon
                      ></v-flex
                    >
                  </v-layout> -->
            </v-card>
          </v-flex>
        </v-layout>
        <!------------------------------------------------------------->

        <v-layout wrap>
          <v-flex xs12>
            <v-layout wrap justify-end py-4>
              <!-- <v-flex xs2 pa-1>
                <v-btn dark color="#3F053C" block download
            target="_blank"
            @click="downloadReciept()"
                  >Print Bill</v-btn
                >
              </v-flex> -->
              <v-flex xs2 pa-1>
                <v-btn dark color="#3F053C" block @click="validate()"
                  >Generate Bill</v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-dialog width="400px" v-model="dialoge">
          <v-card width="400px">
            <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center
                ><span style="color: #3f053c; font-size: 25px"
                  >Delete</span
                ></v-flex
              >
              <v-flex xs8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-card-text class="pa-4 black--text"
              >Are you sure you want to remove this item?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="dialoge = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="deleteItem()"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="400px" v-model="dialoge2">
          <v-card width="400px">
            <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center
                ><span style="color: #3f053c; font-size: 25px"
                  >Delete</span
                ></v-flex
              >
              <v-flex xs8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-card-text class="pa-4 black--text"
              >Are you sure you want to remove this item?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="dialoge = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="deleteOldGold()"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      show: true,
      show1: true,
      discountPresent:false,
      timeout: 2000,
      msg: null,
      estno: null,
      salesmenCode: null,
      date: null,
      juwellaryId: localStorage.getItem("juwellaryId"),
      itemz: [],
      designList: [],
      SupplierList: [],
      List: [],
      saledata: [],
      List2: [],
      oldGoldData: [],
      List3: [],
      customerName: null,
      taxType: localStorage.getItem("GSTstatus"),
      address: null,
      city: null,
      contact: null,
      ItemList: [],
      dtype: false,
      payAmount: null,
      // payType: ["Cash", "Card"],
      discount: null,
      curId: null,
      dialoge: false,
      curId2: null,
      dialoge2: false,
      ItemTotal: 0,
      OldGoldTotal: 0,
      calTotal: 0,
      returnItemTotal: 0,
      returnItemData: 0,
      returnItemNetWt: 0,
      returnId: null,
      payamount: "",
      //payment
      paymentTypeList: ["Cash", "Cheque", "Card"],
      arr1 : [],
      paytype: null,
      pendingAmount: "",
      purchaseAmount: "",
      paymentType: [],
      singleitem: {
        paytype: "",
        purchaseAmount: 0,
      },
      isJewelleryPayable:false,
      billPaidAmount1:"",
      paymentpending:false,
      paymentStatus:"",
      billDiscount:"",
      goldSchemeData:[],
      goldSchemeId:"",
      schemeAmtAvailable:"",
      SchemeMaturedTotalAmount:"",
      newSchemeBalance:"",
      actualBillTotal:0,
    };
  },
  mounted() {
    this.getData();
    this.getOldGoldData();
    this.getReturnGoldData();
  },
  watch: {
    ItemTotal() {
      this.calTotalAmount();
    },
    OldGoldTotal() {
      this.calTotalAmount();
    },
    returnItemTotal() {
      this.calTotalAmount();
    },
  },
  methods: {
    removediscount() {
      this.discountPresent=false;
      this.discount=0;
      var payamount1 = this.calTotal - this.discount;
      this.payamount = payamount1.toFixed(2);
      this.pendingAmount = payamount1;
      this.paymentType = [];
      this.paytype="";
      this.purchaseAmount="";
      this.paymentTypeList=["Cash", "Cheque", "Card"];
      console.log("amtwithdiscount==", this.calTotal);
    },
    caldiscount() {
      this.discountPresent=true;
      var payamount1 = this.calTotal - this.discount;
      this.payamount = payamount1.toFixed(2);
      this.pendingAmount = payamount1;
      this.paymentType = [];
      this.paymentTypeList=["Cash", "Cheque", "Card"];
      console.log("amtwithdiscount==", this.calTotal);

      // this.calTotal=this.payamount;
    },
    calTotalAmount() {
      if(this.paymentStatus=='Pending'){
      console.log("zzzzz")
      if (this.OldGoldTotal || this.returnItemTotal) {
         this.calTotal = this.ItemTotal;
          console.log("initial-caltotal=",this.calTotal)
        if (this.OldGoldTotal) {
          var newgoldtotal= this.calTotal - this.OldGoldTotal;
          this.calTotal = newgoldtotal;
          console.log("afterOldgold-caltotal=",this.calTotal)
        }
        if (this.returnItemTotal) {
          var newreturntotal = this.calTotal - this.returnItemTotal;
          this.calTotal = newreturntotal;
          console.log("afterReturn-caltotal=",this.calTotal)
        }
      }
    
      else {
        this.calTotal = this.ItemTotal;
        console.log("amtwithout==", this.calTotal);
      }
      this.calTotalAmount2();
    }
    else{
      this.calTotalAmount3();
    }
    },
    calTotalAmount2() {
      if(this.billPaidAmount1>0 ){
          console.log("calTotal=",this.calTotal)
          console.log("billPaidAmount=",this.billPaidAmount1)
        if(this.billDiscount){
          this.calTotal = this.calTotal - this.billDiscount;
          console.log("caltotal after applied prev discount==", this.calTotal);
        }
        console.log("this.calTotal1111==", this.calTotal);
        console.log("this.billPaidAmount111111==", this.billPaidAmount1);
          this.calTotal = this.calTotal - this.billPaidAmount1;
          console.log("amtafterminuspaidamt==", this.calTotal);
        }
        //if there is matured gold schemes
        if(this.SchemeMaturedTotalAmount){
          if(this.SchemeMaturedTotalAmount>=this.calTotal){
            this.newSchemeBalance=this.SchemeMaturedTotalAmount-this.calTotal;
            this.calTotal = 0 ;
          }
          else{
            this.newSchemeBalance=0;
            this.calTotal = this.calTotal-this.SchemeMaturedTotalAmount ;
          }
        }
        //
      var calTotal1 = parseFloat(this.calTotal);
      this.payamount = calTotal1.toFixed(2);
      console.log("this.payamount==",this.payamount)
      this.pendingAmount = calTotal1.toFixed(2);
    },
    calTotalAmount3(){
      this.calTotal = this.newpayableAmount;
      this.newSchemeBalance=0;
      var calTotal1 = parseFloat(this.calTotal);
      this.payamount = calTotal1.toFixed(2);
      console.log("this.payamount second time==",this.calTotal)
      this.pendingAmount = calTotal1.toFixed(2);
    },
    validate() {
      if (!this.paymentType) {
        this.msg = "Please choose payment type ";
        this.showsnackbar = true;
      } else if (!this.saledata.totalAmount) {
        this.msg = "Please enter total amount ";
        this.showsnackbar = true;
      } else {
        this.finalPayment();
      }
    },
    // selectThis(item){
    //   console.log("item choosed=", item);
    //   var singleobj = this.goldSchemeData.find((x) => x._id == item);
    //   console.log("singleobj==", singleobj);
    //   if (singleobj.finalTotalAmount) {
    //     this.schemeAmtAvailable = singleobj.finalTotalAmount;
    //     console.log("schemeAmtAvailable=", this.schemeAmtAvailable);
    //   } 
    // },
    getData() {
      axios({
        url: "/sales/getlist",
        method: "get",
        params: {
          saleId: this.$route.query.no,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.List = response.data.data;
            if (this.taxType == "true") {
              console.log("A1")
              var ItemTotal1 = response.data.saledata.totalAmountWithGst;
              this.SchemeMaturedTotalAmount = response.data.saledata.customerId.SchemeMaturedTotalAmount;
              this.goldSchemeData = response.data.saledata.customerId.GoldSchemeCustomerId;
              var ItemTotal2 = parseFloat(ItemTotal1);
              this.ItemTotal = ItemTotal2.toFixed(2);
              console.log("saletotal caltotal==",this.ItemTotal)


            } else {
              console.log("B1")

              var ItemTotal3 = response.data.saledata.totalAmountWithoutGst;
              var ItemTotal4 = parseFloat(ItemTotal3);
              this.ItemTotal = ItemTotal4.toFixed(2);
              console.log("saletotal caltotal==",this.ItemTotal)
            }
            // this.calTotalAmount();
            this.billPaidAmount1 = response.data.saledata.billPaidAmount;
            this.billDiscount = response.data.saledata.billDiscount;
            this.paymentStatus = response.data.saledata.paymentStatus;
            if(this.paymentStatus=='PartiallyPaid'){
              this.newpayableAmount =response.data.saledata.pendingAmount;
              this.actualBillTotal =response.data.saledata.initialBillPayableAmount;
            }
            console.log("billPaidAmount=",this.billPaidAmount1)
            this.saledata = response.data.saledata;
            this.customerName = this.saledata.customerId.customerName;
            this.address = this.saledata.customerId.address;
            this.city = this.saledata.customerId.city;
            this.contact = this.saledata.customerId.mobileNumber;
            this.estno = this.saledata.billNo;
            var date1 = this.saledata.create_date;
            this.date = this.formatDate(date1);
            this.salesmenCode = this.saledata.staffCode;
            console.log("finished root")
            this.calTotalAmount();
          } else {
            // this.msg = response.data.msg;
            // this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem() {
      axios({
        url: "/saleitem/delete",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialoge = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getOldGoldData() {
      axios({
        url: "/oldgolditem/getlist",
        method: "get",
        params: {
          salesId: this.$route.query.no,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.List2 = response.data.data;
            var OldGoldTotal1 = response.data.bill.totalAmount;
            this.OldGoldTotal = OldGoldTotal1.toFixed(2);
            this.oldGoldData = response.data.bill;
          } else {
            // this.msg = response.data.msg;
            // this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteOldGold() {
      axios({
        url: "/oldgolditem/delete",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curId2,
        },
      })
        .then((response) => {
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialoge = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getReturnGoldData() {
      axios({
        url: "/returnitem/getlist",
        method: "get",
        params: {
          salesId: this.$route.query.no,
          jewelleryId: this.juwellaryId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.List3 = response.data.data;
            this.returnId = response.data.bill._id;

            var returnItemTotal1 = response.data.bill.totalBillAmount;
            this.returnItemTotal = returnItemTotal1.toFixed(2);
            var returnItemNetWt1 = response.data.bill.totalNetWeight;
            this.returnItemNetWt = returnItemNetWt1.toFixed(2);
          } else {
            // this.msg = response.data.msg;
            // this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    downloadInvoices(){
      if(this.List.length>0){
        console.log("r1")
        this.downloadReciept();
      }
      if(this.List2.length>0){
        console.log("r2")
        this.downloadOldGoldReciept();
      }
      if(this.List3.length>0){
        console.log("r3")
        this.downloadReturnReciept();
      }
      console.log("r4")
      this.$router.push("/salesReport");
    },
    downloadReciept() {
      axios({
        method: "get",
        url: "/download/invoice",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.no,
        },
        responseType: "blob",
      })
        .then((response) => {
          // this.appLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Receipt.pdf");
          document.body.appendChild(link);
          link.click();
          // this.$router.push("/salesReport");
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    downloadOldGoldReciept() {
      axios({
        method: "get",
        url: "/download/purchaseinvoice",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.no,
        },
        responseType: "blob",
      })
        .then((response) => {
          // this.appLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "PurhaseInvoice.pdf");
          document.body.appendChild(link);
          link.click();
          // this.$router.push("/salesReport");
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    downloadReturnReciept() {
      axios({
        method: "get",
        url: "/download/voucher",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.no,
        },
        responseType: "blob",
      })
        .then((response) => {
          // this.appLoading = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Voucher.pdf");
          document.body.appendChild(link);
          link.click();
          // this.$router.push("/salesReport");
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    finalPayment() {
      if(this.payamount<=0){
        this.isJewelleryPayable=true;
      }
      else{
        this.isJewelleryPayable=false;
      }
      axios({
        url: "/sales/payment",
        method: "POST",
        data: {
          jewelleryId: this.juwellaryId,
          isJewelleryPayable: this.isJewelleryPayable,
          saleId: this.$route.query.no,
          oldGoldId: this.oldGoldData._id,
          saleTotal: this.ItemTotal,
          // appliedOldGoldAmount: this.calTotal,
          oldGoldAmount: this.OldGoldTotal,
          paymentType: this.paymentType,
          billPendingAmount: this.pendingAmount,
          billDiscount: this.discount,
          payAmount: this.payamount,
          GoldSchemeBalanceAmount: this.newSchemeBalance,
          goldRate: localStorage.getItem("goldRate"),
          returnId: this.returnId,
          returnGoldAmount: this.returnItemTotal,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            localStorage.removeItem("billType");
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.ItemTotal="";
          this.calTotal = "";
         this.OldGoldTotal="";
          this.paymentType=[];
          this.pendingAmount="";
         this.discount="";
          this.payamount="";
          this.returnId="";
          this.returnItemTotal="";
            this.downloadInvoices();
            // this.$router.push("/salesReport");
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
    //payment
    addValues() {
      console.log("purchaseAmount=", this.purchaseAmount);
      console.log("pendingAmount=", this.pendingAmount);
      if (this.paytype != "" && this.paytype != null && this.purchaseAmount != "") {
        console.log("purchaseAmount==",this.purchaseAmount);
        console.log("pendingAmount==",this.pendingAmount)

        if(parseFloat(this.purchaseAmount)<=parseFloat(this.pendingAmount)){
        this.singleitem.paytype = this.paytype;
        this.singleitem.purchaseAmount = this.purchaseAmount;
        console.log("type=", this.paytype);
        console.log("purchaseAmount=", this.purchaseAmount);

        console.log("1");
        
        this.arr1.push({ type: this.paytype, purchaseAmount: this.purchaseAmount });
        this.paymentTypeList = this.paymentTypeList.filter((x) => x !== this.paytype);
        console.log("paymentTypeList=",this.paymentTypeList)
        // this.paytype = "cash";
        // arr1.push({ type: this.paytype, purchaseAmount: this.purchaseAmount });
        // this.paymentTypeList = this.paymentTypeList.filter((x) => x != this.paytype);
        this.calcPrice2(this.purchaseAmount);
        }
        else if(parseFloat(this.purchaseAmount)>parseFloat(this.pendingAmount)){
          this.msg = "Amount is geater than pending amount";
        this.showsnackbar = true;
        }
        else{
          this.msg = "else case";
        this.showsnackbar = true;
        }
       

      } else {
        this.msg = "fields cannot be empty";
        this.showsnackbar = true;
      }
    },
    addVariant() {
      console.log("3");
      this.paymentType.push(JSON.parse(JSON.stringify(this.singleitem)));
      this.paytype = null;
      this.purchaseAmount = null;
      console.log("paymentType==", this.paymentType);
    },
    calcPrice2(val1) {
      console.log("2");
      var newPendingAmt = this.pendingAmount - val1;
      this.pendingAmount = newPendingAmt.toFixed(2);
      console.log("pendingAmount in calprice2=", this.pendingAmount);
      this.addVariant();
    },
    calcPrice(vall,i) {
      console.log("val=", vall);
      console.log("pendingAmount-before-remove=", this.pendingAmount);
      var newPendingAmt = parseFloat(this.pendingAmount) + parseFloat(vall);
      this.pendingAmount = newPendingAmt.toFixed(2);
      this.paymentType.splice(i, 1)
      console.log("paymentType==",this.paymentType)
      console.log("pendingamt after remove=", this.pendingAmount);
    },
    //
  },
};
</script>
